import React, {useEffect, useState} from 'react'
import RtfToHtml from '@iarna/rtf-to-html'
import {ReportFrame} from '../components/ExternalReportStyles'

export const TxtPreview = ({src}) => {

  const [html, setHtml] = useState()

  useEffect(() => {
    console.log(src)
    fetch(src)
      .then(e=>e.text())
      .then((str) => setHtml(str.replace(/\n/g, "<br/>")))
  },[src])

  return <ReportFrame txtReport as="div" dangerouslySetInnerHTML={{__html: html}}/>
}
