import { Button, Icon, Loading } from '@startlibs/components';
import {useTitle} from '@startlibs/core';
import { wrapLazy } from '@startlibs/utils';
import React from 'react'

import {CCDAPreview} from './CCDAPreview'
import {
  DownloadButton,
  ImageFrame,
  NoPreview,
  PageHeaderWrapper,
  ReportFrame
} from '../components/ExternalReportStyles';
import {PageContainer} from '../components/PageLayout';
import {PathologyPreview} from './PathologyPreview'
import {TxtPreview} from './TxtPreview'
import {en_US} from '../components/date.i18n/en_US'
import {formatDateNoUTC} from '../utils/utils'
import {jwtGetFetcher} from '../utils/authFetch'
import {useDownload} from '../utils/downloadFile'
import {DOWNLOADER_URL} from '../config/DownloaderConfig'

const STORAGE_ORIGIN = window.STORAGE_ORIGIN || window.location.origin.replace(/\/\/view(er)?/, "//storage")
const PATHOLOGY_VIEWER_ORIGIN = window.PATHOLOGY_VIEWER_ORIGIN || window.location.origin.replace(/\/\/view(er)?/, "//openslide")

const lazyRecordInfo = wrapLazy((recordId, jwt) =>
  // process.env.NODE_ENV === 'development' ? Promise.resolve({format:'CCDA',viewUrl: '/public/sample.xml',whenModifiedEpochMilli: Date.now()}) :
  jwtGetFetcher(jwt)(STORAGE_ORIGIN + `/record/${recordId}`)
)

const isPdf = (record) => record?.fileExtension === "pdf" || record?.fileType === "application/pdf"
const isTxt = (record) => record?.fileExtension === "txt" || record?.fileExtension === "obx" || record?.fileType === "text/plain"
const isCCDA = (record) => record?.format?.toUpperCase() === "CCDA"
const isPathology = (record) => record.recordClass === 'Pathology'
const isMultiFile = (record) => ['Mirax', 'HamamatsuVMU', 'HamamatsuVMS'].indexOf(record.format) >= 0
const isImg = (record) => ["png", "jpeg", "jpg", "bmp", "gif", "webp"].indexOf(record?.fileExtension) >= 0 || ["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif", "image/webp"].indexOf(record?.fileType) >= 0

const getPathologyURL = (record, jwt) => `${PATHOLOGY_VIEWER_ORIGIN}/view/${record.recordUID}?jwt=${jwt}`

export const ReportPage = ({recordId, jwt}) => {
  useTitle("File Viewer")

  const record = lazyRecordInfo.use(recordId, jwt).read()


  const downloadUrl = DOWNLOADER_URL() + `${recordId}&jwt=${jwt}${isMultiFile(record) && record?.info?.description ? '&originalFilename=' + record.info.description : ''}`
  const viewUrl =
    // process.env.NODE_ENV === 'development' ? record.viewUrl :
    DOWNLOADER_URL() + `${recordId}&jwt=${jwt}&attachment=false`


  return <> <PageContainer isCCDA={isCCDA(record) || isPathology(record)} isViewer appLayout>
    <PageHeaderWrapper>
      <div className="left-wrapper">
        <div className="patient-label">File:</div>
        <h3>{isMultiFile(record) ? record.info?.description || record.originalFileName : record.originalFileName}</h3>
      </div>
      <div className="right-wrapper">
        <div>
          {
            isMultiFile(record)
              ? <AsyncDownloadButton
                jwt={jwt}
                record={record}
              />
              : <Button.a
                small
                href={downloadUrl}
                download={record.originalFileName || true}
                icon="download"
              >Download</Button.a>
          }
        </div>
        <div className="last-updated">Last updated: {formatDateNoUTC(new Date(record.whenModifiedEpochMilli), "LL, dd, yyyy - hh:mm", en_US)}</div>
      </div>
    </PageHeaderWrapper>

    {
      isPdf(record)
        ? <ReportFrame pdfReport src={viewUrl}/>
        : isTxt(record)
        ? <TxtPreview txtReport src={viewUrl}/>
        : isCCDA(record)
          ? <CCDAPreview txtReport src={viewUrl}/>
          : isPathology(record)
            ? <PathologyPreview txtReport src={getPathologyURL(record, jwt)}/>
            : isImg(record)
              ? <ImageFrame as="img" htmlReport src={viewUrl}/>
              : <NoPreview>
                <div className="content">
                  <Icon icon="hide"/>
                  <h3>No preview available.</h3>
                  <p>Download will begin shortly.</p>
                </div>
              </NoPreview>
    }
    <div css="margin-top: 1.5rem; text-align: right;">
      {
        isMultiFile(record)
          ? <AsyncDownloadButton
            jwt={jwt}
            record={record}
          />
          : <Button.a
            small
            href={downloadUrl}
            download={record.originalFileName || true}
            icon="download"
          >Download</Button.a>
      }
    </div>
  </PageContainer>
  </>
}


const AsyncDownloadButton = ({jwt, record}) => {
  const downloadLoadUrl = (record, filename) =>
    jwtGetFetcher(jwt)(STORAGE_ORIGIN + `/download/async/${record.recordUID}`).then(({fileUID}) => ({url: () => STORAGE_ORIGIN + `/download/file/${fileUID}?t=${jwt}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}`}))

  const [tryDownload, downloadIsLoading, downloadIsStating, downloadFailed] = useDownload(jwt, record, downloadLoadUrl, record?.info?.description)

  return <DownloadButton
    small
    onClick={tryDownload}
    icon={downloadIsLoading ? undefined : downloadIsStating ? "check" : "download"}
  >
    {
      downloadIsLoading ?
        <><Loading size={15} borderWidth={3}/>Preparing...</>
        :
        downloadIsStating ?
          <>Started</>
          :
          <>Download</>
    }
  </DownloadButton>
}
