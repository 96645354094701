import React, {useEffect, useState} from 'react'
import RtfToHtml from '@iarna/rtf-to-html'
import {ReportFrame} from '../components/ExternalReportStyles'


const IFRAME_ROOT = process.env.NODE_ENV === 'development' ? '' : '.'
export const CCDAPreview = ({src}) => {

  return <ReportFrame isCCDA src={IFRAME_ROOT + '/public/ccda/index.html?src='+encodeURIComponent(src)}/>
}
