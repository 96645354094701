import { Button } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import React from 'react';
import styled, { css } from 'styled-components';

export const ReportViewerNavbar = styled(({className, title, goBack, withReturn}) => {

    return <div className={className}>
      <div className="navbar-content">
        {withReturn && <Button outline white icon="arrow-left-line" onClick={goBack}>Return</Button>}
        <div className="title">
          {title ? title : "Purview External Record Viewer"}
        </div>
      </div>
    </div>
})`
  height: 4rem;
  background: ${getColor('gray90')};
  left:0;
  right:0;
  top: 0;
  position: fixed;
  z-index: 100;
  .navbar-content {
    max-width: 210mm;
    min-width: 300px;
    margin: 0 auto;
    padding: 0 .5rem;
    display: flex;
    align-items: center;
    justify-content: stretch;
    .title {
      text-align: center;
      color: white;
      line-height: 4rem;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${props => props.withReturn && css`
        padding-right: calc(83px + .5rem);
      `}
      flex-grow: 1;
    }
    ${Button} {
      flex-shrink: 0;
      margin-right: .5rem;
    }
    ${media.max(450)`
      ${props => props.withReturn && css`
        justify-content: flex-start;
        .title {
          padding-right: 0;
          text-align: left;
        }
      `}
    `}
  }
  > div {
    max-height: 4rem;
  }
`
