import { Button, Icon, Loading } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import styled, { css } from 'styled-components';

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  .left-wrapper {
    flex-grow: 1;
  }
  h3 {
    font-size: 17px;
    margin: 0;
  }
  .last-updated {
    color: ${getColor('gray120')};
    font-size: 11px;
    margin-top: .5rem;
  }
  .right-wrapper {
    text-align: right;
    margin-left: 1rem;
    padding-top: .5rem;
    flex-grow: 1;
    flex-shrink: 0;
  }
`

export const DownloadButton = styled(Button)`
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: -2px;
  }
`

export const ReportFrame = styled.iframe`
  width: 100%;
  max-width: 210mm;
  min-width: 300px;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray180')};
  background: white;
  ${props => props.txtReport && css`
    background: white;
    padding: 2rem;
    border: 1px solid ${getColor('gray210')};
  `}
  ${props => (props.pdfReport || props.htmlReport) && css `
    height: 66vh;
  `}
  ${props => props.htmlReport && css `
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
  ${props => props.isCCDA && css`
    max-width: 100%;
    height: 76vh;
  `}
`

export const ImageFrame = styled(ReportFrame)`
  height: auto;
  width: 100%;
  min-height: auto;
  background: rgba(0,0,0,0.025);
  ${props => props.src && css`
    background-image: url(${props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
  `}
`

export const NoPreview = styled.div`
  width: 100%;
  max-width: 210mm;
  min-width: 300px;
  height: 64vh;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray180')};
  text-align: center;
  background: white;
  display: flex;
  align-items: center;
  .content {
    margin-top: -3rem;
    ${Icon} {
      font-size: 36px;
      color: ${getColor('main')};
    }
    h3 {
      color: ${getColor('main')};
      margin-botom: .25rem;
    }
    p {
      color: ${getColor('gray150')};
      margin: 0;
    }
  }
`
