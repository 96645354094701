import {Icon} from '@startlibs/components'
import {callIfFunction, getColor} from '@startlibs/utils'
import React from 'react';
import styled from 'styled-components'

import {Card} from '../../components/PageLayout'
import { ReportViewerNavbar } from '../../components/ReportViewerNavbar';

const ExpiredLinkWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width: 400px;
  width: 100%;
  ${Card} {
    margin-bottom: 1rem;
    padding-bottom: 4rem;
  }
  ${Icon} {
    font-size: 5rem;
    color: ${getColor('gray180')};
  }
  h1 {
    font-size: 18px;
    margin: 1rem auto;
    color: ${getColor('gray120')};
  }
  p {
    margin: 0 .25rem;
  }
`

export class ProviderReleaseError extends React.Component {

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    const errorData = error && callIfFunction(error.get)
    if (errorData && errorData.ignoreError) {
      return {withoutUX: true}
    }
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  closeDialog = () => {
    this.setState({closingDialog: true})
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }

  render() {
    if (this.state.withoutUX) {
      return null
    }
    return <>
      <ReportViewerNavbar/>
      <ExpiredLinkWrapper>
        <Card>
          <Icon icon="warning"/>
          <h1>Unable to access record.</h1>
          <p>The link you are trying to access is not valid or you might not have permission to see this record.</p>
        </Card>
      </ExpiredLinkWrapper>
    </>
  }
}
