import {DialogFooter, DialogIcon, Icon} from '@startlibs/components'
import {Errors} from '@startlibs/form'
import {css} from 'styled-components'
import {getColor} from '@startlibs/utils'

export default {
  startlibs: {
    DefaultStyle: css`
      html, body {
        background: white;
        font-weight: 300;
        font-family: "Montserrat", "HCo Gotham SSm", "Gotham SSm A","Gotham SSm B", sans-serif;
      }
      *:focus, button:focus {
        box-shadow: 0 0 2px 3px #98dcff;
        outline: none;
      }
      a {
        cursor: pointer;
        &.link {
          text-decoration: underline;
          font-weight: 600;
          color: ${getColor('main')};
          cursor: pointer;
          :hover {
            color: #064785;
          }
        }
        &.link.gray {
          color:  ${getColor('gray150')};
          &:hover {
            color: ${getColor('main')};
          }
        }
      }
    `,
    Button: css`
          border-radius: 4px;
          padding-bottom: 1rem;
          font-size: 14px;
          padding: 1rem 1.5rem;
          font-weight: 400;
          box-shadow: none !important;
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          :focus {
            box-shadow: 0 0 2px 3px #98dcff !important;
            outline: none;
          }
          ${props => props.small && `
            padding: .5rem 1rem;
          `}
          ${Icon} {
            vertical-align: -5px;
          }
          ${props => props.highlight && css`
            :hover {
              background-color: #064785;
            }
          `}
          ${props => props.alert && css`
            border: 1px solid ${getColor('alert')};
          `}
          ${props => !props.highlight & !props.outline && !props.alert && css`
            background: white;
            border: 1px solid ${getColor('main')};
            color: ${getColor('main')} !important;
            :hover, :active {
              border: 1px solid #064785;
              color: #064785 !important;
              background: white;
            }
          `}
      `,
    ContextMenu: `
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
        border-radius: 6px;
      `,
    Popup: `
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
        border-radius: 4px;
      `,
    ListItem: css`
        ${props => props.hover && css`
          & > a, & > label {
            background-color: #064785;
            color: white;
          }
        `}
        ${props => props.highlight && css`
            color: #064785;
            font-weight: 600;
            background-color: #f6f6f5;
        `}
      `,
    Dialog: css`
        background-color: white;
        border-radius: 4px;
        h2 {
          border-bottom: none;
          padding-bottom: 0;
        }
        ${DialogFooter} {
          border-top: none;
        }
        ${DialogIcon} {
          font-size: 100px;
        }
      `,
    FieldLabel: `
        font-weight: 600;
      `,
    TextInput: `
        border-radius: 4px;
        :focus {
          box-shadow: 0 0 2px 3px #98dcff !important;
          outline: none;
          border: 1px solid #064785;
        }
      `,
    ToggleCheckbox: css`
        width: 2.75rem;
        height: 18px;
        border-radius: 18px;
        background: ${props => props.checked ? getColor('main') : 'rgba(0,0,0,0.2)'};
        display: inline-block;
        position: relative;
        font-weight: 600;
        :after {
          transition: none;
          width: 14px;
          height: 14px;
          background: white;
          ${props => props.checked ? css`
            margin-left: -2px;
            `: css`
            margin-left: 2px;
          `}
        }
      `,
    AttachmentBox: css`
      ${props => !props.failedUpload && css`
        background: white;
      `}
      border: 1px solid ${getColor('gray210')};
      ${props => props.failedUpload && css`
        border: 1px solid ${getColor('alert')};
      `}
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 4px;
    `,
    AttachmentBoxButton: css`
      background: white;
      border: 1px solid;
      border-radius: 4px;
      :hover {
        background-color: white;
        border-color: #064785;
        color: #064785;
      }
    `,
    /*IconRadioBox: css`
        border-radius: 6px;
        ${Icon} {
          font-size: 120px;
          line-height: 56px;
        }
        ${IconRadioBox.Text} {
          padding: 1rem;
          box-shadow: inset 0 -3px 0 0 rgba(0,0,0,.15);
        }
      `,*/
    ErrorItem: css`
        background: ${getColor('alert')};
        color: white;
        border: 0;
        ${Errors.CloseIcon} {
          color: white;
          background-color: rgba(0, 0, 0, 0.2);
          width: 20px;
          height: 20px;
          line-height: 20px;
          &:hover {
            background: rgba(0,0,0,0.25);
          }
        }
        a {
          color: white;
          font-weight: 600;
        }
      `
  },
  Field: {margins: {none: '0', big: '2rem', 'default': '1rem'}},
  colors: {
    gray30: '#1e1e1e',
    gray60: '#3c3c3c',
    gray90: '#5a5a5a',
    gray120: '#787878',
    gray150: '#969696',
    gray180: '#b4b4b4',
    gray210: '#d2d2d2',
    gray240: '#f0f0f0',
    main: '#1C75CD',
    secondary: '#28AAE1',
    alert: '#B51809',
    warning: '#F26529',
    success: '#197338',
    physician: '#00a99d',
    organization: '#A82054',
    lightGreen: '#E7F2E9',
    lightYellow: '#F8F2DF',
    lightBlue: '#EFF4F7'
  },
  iconFont: 'mymsk-icons',
  icons: {
    'download': '\ue909',
    'move': '\ue90a',
    'zip-file': '\ue902',
    'edit-box': '\ue903',
    'arrow-down': '\ue910',
    'arrow-left': '\ue90e',
    'arrow-right': '\ue90d',
    'arrow-up': '\ue90f',
    'view': '\ue90c',
    'x': '\ue90b',
    'plus-circle': '\ue907',
    'warning': '\ue911',
    'plus': '\ue907',
    'pdf': '\ue904',
    'video': '\ue901',
    'image-line': '\ue906',
    'zip': '\ue902',
    'dicom': '\ue905',
    'delete': '\ue908',
    'note': '\ue912',
    'file': '\ue900',
    'svs': '\ue913',
    'check': '\ue914',
    'pathology': '\ue918',
    'txt': '\ue917',
    'xml': '\ue916',
    'ccda': '\ue915'
  }
}
