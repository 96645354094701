import {getFetcherWithResponse, callIfFunction} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {authGetFetcher, jwtGetFetcher, jwtPostFetcher} from './authFetch'

export const downloadFile = (launchURL) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.location.href = launchURL
  } else {
    const link = document.createElement('a')
    link.href = launchURL
    link.style.visibility = "hidden"
    link.setAttribute("download", "")
    document.body.appendChild(link);
    link.click()
    link.remove()
  }
}

var getOrigin = function (href) {
  const [protocol, , host] = href.split("/")
  return protocol + "//" + host;
};

export const checkForDownload = (url) => new Promise((res, rej) => {
  let tries = 0;
  if (window.xdomain) {
    const origin = getOrigin(url)
    window.xdomain.slaves({
      [origin]: "/public/proxy.html"
    })
  }
  const check = () => getFetcherWithResponse(url, null, {method: 'HEAD'})
    .then(([, response]) => response.status === 200 ? res(url) : setTimeout(check, 1000))
    .catch(([req, response]) => {
      if (!response) {
        tries++;
        if (tries > 5) {
          rej(response)
        } else {
          setTimeout(check, 1000)
        }
      } else if (response.status === 404) {
        rej(response.status)
        console.log('missing file')
      } else if (response.status === 500) {
        rej(response.status)
        console.log('server error')
      } else {
        rej(response.status)
      }
    })
  check()
})


export const useDownload = (
  jwt, record,
  downloadLoadUrl = (record) =>
    jwtPostFetcher(callIfFunction(jwt))(`/api/downloadLink/`,
      record.nonCompliantFiles
        ? {files: record.nonCompliantFiles.map(f => f.uid + "." + f.fileExtension)}
        : {studyUid: record.studyUID}
    ),
  filename
) => {

  const loading = useToggle()
  const started = useToggle()
  const downloadError = useToggle()

  const download = () => {
    downloadError.close()
    loading.wait(
      callIfFunction(downloadLoadUrl, record, filename)
        .then(({url}) => checkForDownload(callIfFunction(url)))
        .then(downloadFile)
    ).then(() => {
        started.open();
        started.closeAfter(1500);
      })
      .catch(downloadError.open)
  }

  return [download, loading.isOpen, started.isOpen, downloadError.isOpen]
}

